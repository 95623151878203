import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const toCurrencyFormat = (n: number) => {
  return formatter.format(n);
};

export const toDate = (str: string) => {
  return new Date(str);
};

export const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString();
};

export const formatDateWithTime = (dateString: string) => {
  return dayjs(dateString).format('DD/MM/YYYY hh:mm a');
};

export const formatTimeString = (dateString: string) => {
  return dayjs(dateString).format('hh:mm a');
};

export const formatTime = (time: string) => {
  const formatedTime = dayjs(time, 'H:mm:ss').format('hh:mm a');
  return formatedTime;
};
