import { configureStore } from '@reduxjs/toolkit';
import chaletApi from '../api/chaletApi';
import authSlice from './slices/authSlice';
import orderDetailSlice from './slices/orderDetailSlice';
import salesSlice from './slices/salesSlice';
import userSlice from './slices/userSlice';
import modalSlice from './slices/modalSlice';
import shopAdminDrawer from './slices/shopDrawer';
import persistedCartSlice from './slices/cartSlice';
import persistedCurrentShopSlice from './slices/currentShopSlice';
import { persistStore } from 'redux-persist';
import persistedlocationSlice from './slices/locationSlice';
import thunk from 'redux-thunk';
import persistedUserAuthSlice from './slices/userAuthSlice';
import persistedDeviceSlice from './slices/deviceSlice';

const middleware = [thunk, chaletApi.middleware];

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: userSlice.reducer,
    orderDetail: orderDetailSlice.reducer,
    sales: salesSlice.reducer,
    shopAdminDrawer: shopAdminDrawer.reducer,
    modalSlice: modalSlice.reducer,
    cartSlice: persistedCartSlice, //Slice persist
    currentShopSlice: persistedCurrentShopSlice, //Slice persist
    locationSlice: persistedlocationSlice, //Slice persist
    userAuthSlice: persistedUserAuthSlice, //persist
    device: persistedDeviceSlice, //persist device
    [chaletApi.reducerPath]: chaletApi.reducer,
  },
  middleware: middleware,
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
