import React from 'react';
import { Box, Card, Container, Grid, Paper, Typography } from '@mui/material';
import { ReactComponent as ElchaletLogo } from '../../../assets/ic_logo_no_title.svg';

export const AboutUs = () => {
  return (
    <Container maxWidth="sm">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: '90vh' }}
      >
        <Grid item>
          <Box display="flex" justifyContent="center" margin={2}>
            <Card
              style={{
                borderRadius: 20,
                padding: '3px',
                backgroundColor: '#EF3D32',
              }}
            >
              <Box width={100} height={100} display="flex" alignItems="center">
                <ElchaletLogo />
              </Box>
            </Card>
          </Box>

          <Typography textAlign={'center'} sx={{ mt: 1, mb: 1 }} variant="h4">
            Chalet App
          </Typography>
          <Typography textAlign={'center'}>
            Chalet App es producto de la motivación de crear herrramientas
            tecnológicas de ultima generación en Chalatenango, El Salvador.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
