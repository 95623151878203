import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Location } from 'types/Location';

export type LocationState = {
  id?: number;
  location: Location | null;
  name: string | null | undefined;
  address: string | null;
  reference: string | null;
  sectorId: number | null;
};

const initialState: LocationState = {
  location: null,
  name: null,
  address: null,
  reference: null,
  sectorId: null,
};

const locationPersistConfig = {
  key: 'location',
  storage: storage,
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    addLocation: (state, action: PayloadAction<LocationState>) => {
      const { location, address, reference, name, id, sectorId } =
        action.payload;
      state.location = location;
      state.address = address;
      state.name = name;
      state.reference = reference;
      state.id = id;
      state.sectorId = sectorId;
    },
    clearLocation: (state) => {
      return initialState;
    },
  },
});

const persistedlocationSlice = persistReducer(
  locationPersistConfig,
  locationSlice.reducer,
);

export const { addLocation, clearLocation } = locationSlice.actions;

export default persistedlocationSlice;
