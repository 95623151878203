import {
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import routesConstants from 'utils/routesConstants';

export const SingIn = () => {
  return (
    <Container maxWidth="sm">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: '90vh' }}
      >
        <Grid item sx={{ width: 350 }}>
          <Paper sx={{ padding: '20px', borderRadius: '10px' }} elevation={2}>
            <Stack
              display={'flex'}
              direction={'column'}
              justifyContent={'center'}
              spacing={1}
            >
              <Typography textAlign={'center'}>Debes iniciar sesión</Typography>
              <Button component={Link} to={routesConstants.login} variant="contained">
                Iniciar Sesion
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
