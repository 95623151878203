import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { toCurrencyFormat } from "utils/formatter";

type VariationOption = {
  id: number;
  value: string;
  addedValue: number;
  variationId: number;
};

type Variation = {
  id: number;
  name: string;
  type: "multiple" | "single";
  required: boolean;
  options: VariationOption[];
};

type ProductVariationProps = {
  variations: Variation[];
  onChange: (data: {
    variationId: number;
    variationOptionId: number;
    value: string;
    addedValue: number;
    type: string;
  }) => void;
  initial: (initialRadioState: Record<string, any>) => void;
};

export const OrderDetailStep: React.FC<ProductVariationProps> = ({
  variations,
  onChange,
  initial,
}) => {
  const [checkboxState, setCheckboxState] = React.useState<
    Record<string, boolean>
  >({});

  const [radioState, setRadioState] = React.useState<
    Record<string, VariationOption>
  >({});

  React.useEffect(() => {
    const initialRadioState: Record<string, VariationOption> = {};
    const initialBody: Record<string, VariationOption> = {};

    if (variations.length > 0) {
      const requiredVariations = variations.filter(
        (variation) => variation.required === true,
      );

      requiredVariations.map((variation) => {
        if (variation.type === "single") {
          const firstOptions = variation.options[0];

          initialRadioState[firstOptions.variationId] = {
            id: firstOptions.id,
            addedValue: firstOptions.addedValue,
            value: firstOptions.value,
            variationId: firstOptions.variationId,
          };

          initialBody[firstOptions.id] = {
            id: firstOptions.id,
            addedValue: firstOptions.addedValue,
            value: firstOptions.value,
            variationId: firstOptions.variationId,
          };
        }
      });
    }

    setRadioState(initialRadioState);

    initial(initialBody);
  }, [variations]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    variationId: number,
    variationOptionId: number,
    value: string,
    addedValue: number,
  ) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));

    onChange({
      variationId,
      variationOptionId,
      value,
      addedValue,
      type: "checkbox",
    });
  };

  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    variationId: number,
  ) => {
    const selectedOption = variations
      .find((variation) => variation.id === variationId)
      ?.options.find((option) => option.value === event.target.value);

    if (selectedOption) {
      const { id: variationOptionId, value, addedValue } = selectedOption;

      setRadioState((prevState) => ({
        ...prevState,
        [variationId]: {
          id: variationOptionId,
          variationId: variationId,
          value: value,
          addedValue: addedValue,
        },
      }));

      onChange({
        variationId,
        variationOptionId,
        value,
        addedValue,
        type: "radio",
      });
    }
  };
  return (
    <>
      <Divider sx={{ mt: 2, mb: 2 }} />
      {variations.map((variation) => {
        if (variation.type === "multiple") {
          return (
            <Box key={variation.id}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {variation.name}
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {variation.required ? "Requerido" : "Opcional"}
                </Typography>
              </Box>
              <FormControl
                key={variation.id}
                sx={{ mt: 1 }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  {variation.options.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={checkboxState[item.value] || false}
                          onChange={(event) =>
                            handleCheckboxChange(
                              event,
                              item.variationId,
                              item.id,
                              item.value,
                              item.addedValue,
                            )
                          }
                          name={item.value}
                        />
                      }
                      label={
                        <Stack direction={"column"}>
                          <Typography>{item.value}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            {item.addedValue === 0
                              ? ""
                              : `+ (${toCurrencyFormat(item.addedValue)})`}
                          </Typography>
                        </Stack>
                      }
                    />
                  ))}
                </FormGroup>
                <FormHelperText>Escoge las opciones que quieras</FormHelperText>
              </FormControl>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Box>
          );
        } else if (variation.type === "single") {
          return (
            <Box key={variation.id}>
              <Box style={{ display: "flex" }}>
                <Stack
                  flex={1}
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    {variation.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {variation.required ? "(Incluye 1)" : ""}
                  </Typography>
                </Stack>
                <Typography flex={0} sx={{ fontWeight: "bold" }}>
                  {variation.required ? "Requerido" : "Opcional"}
                </Typography>
              </Box>
              <FormControl>
                <RadioGroup
                  aria-labelledby={variation.name}
                  id={variation.id.toString()}
                  name={variation.id.toString()}
                  value={radioState[variation.id]?.value || ""}
                  onChange={(event) => handleRadioChange(event, variation.id)}
                >
                  {variation.options.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      value={item.value}
                      control={<Radio />}
                      label={
                        <Stack direction={"column"}>
                          <Typography>{item.value}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            {item.addedValue === 0
                              ? ""
                              : `+(${toCurrencyFormat(item.addedValue)})`}
                          </Typography>
                        </Stack>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Box>
          );
        }

        return null;
      })}
    </>
  );
};
