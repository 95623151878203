import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

type CurrentShopState = {
  shopId: number | null;
};

const initialState: CurrentShopState = {
  shopId: null,
};

const currentShopPersistConfig = {
  key: 'currentShop',
  storage: storage,
};

export const currentShopSlice = createSlice({
  name: 'currentShop',
  initialState,
  reducers: {
    updateShopId: (state, action: PayloadAction<number | null>) => {
      state.shopId = action.payload;
    },
    clearShopId: (state) => {
      state.shopId = null;
    },
  },
});

const persistedCurrentShopSlice = persistReducer(
  currentShopPersistConfig,
  currentShopSlice.reducer,
);

export const { updateShopId, clearShopId } = currentShopSlice.actions;

export default persistedCurrentShopSlice;
