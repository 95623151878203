export const getCurrentDay = () => {
  const date = new Date();
  const day = date.getDay();
  return day === 0 ? 7 : day;
};

export const daysOfTheWeek: { [key: number]: string } = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
};
