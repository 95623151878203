import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SalesState = {
  isOpen: boolean;
};

const initialState: SalesState = {
  isOpen: true,
};

export const salesSlice = createSlice({
  name: 'shop-admin-drawer',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleDrawer } = salesSlice.actions;

export default salesSlice;
