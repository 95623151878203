import { Box, Card, styled } from '@mui/material';

export const CustomBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& img': {
      width: 80,
      height: 80,
    },
  },
}));

export const StepperBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: 10,
  backgroundColor: '#FFFFFF',
  [theme.breakpoints.down('sm')]: {
    zIndex: 99,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.2)',
  },
}));
