import { Box, Button, Divider, Typography, styled } from "@mui/material";
import chaletApi from "api/chaletApi";
import React from "react";
import toast from "react-hot-toast";
import { BranchById } from "types/Branch";
import { VariationOption } from "types/Order";
import { toCurrencyFormat } from "utils/formatter";

type AddOrderDetailPreviewProps = {
  handleBackStep: () => void;
  handleResetStep: () => void;
  variations: VariationOption[];
  product: any;
  quantity: number;
  notes: string;
  orderId: number;
  branch: BranchById;
};

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 8,
  position: "relative",
  [theme.breakpoints.down("md")]: {
    "& img": {
      width: 125,
      height: 125,
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& img": {
      width: 80,
      height: 80,
    },
    "& .MuiTypography-root": {
      fontSize: "1rem",
    },
    "& .MuiBox-root": {
      margin: 3,
    },
  },
}));

export const AddOrderDetailPreview: React.FC<AddOrderDetailPreviewProps> = ({
  handleBackStep,
  handleResetStep,
  variations,
  product,
  quantity,
  notes,
  orderId,
  branch,
}) => {
  const [createOrderDetail] = chaletApi.useCreateOrderDetailMutation();

  const onSubmit = () => {
    createOrderDetail({
      shopId: branch.shopId,
      branchId: branch.id,
      productId: product.id,
      quantity: quantity,
      orderId,
      name: product.name,
      notes,
      productPrice: product.price,
      variations: variations.map((variation) => ({
        variationOptionId: variation.id,
        increase: variation.addedValue,
      })),
    })
      .unwrap()
      .then(() => {
        toast.success("Cantidad actualizada");

        handleResetStep();
      })
      .catch(() => {
        toast.error("Error al actualizar la cantidad");
      });
  };

  return (
    <Box
      key={product.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 4,
        mb: 2,
      }}
    >
      <Box>
        <Box key={product.id}>
          <CustomBox>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {product.image && (
                <Box mr={1}>
                  <img
                    width={75}
                    height={75}
                    src={product.image}
                    style={{
                      borderRadius: "5px",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              )}
              <Box display={"flex"} flexDirection={"column"} flex={1}>
                <Typography style={{ fontWeight: "bold", fontSize: 16 }}>
                  {product.name}
                </Typography>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {toCurrencyFormat(product.price)} x {quantity} + variaciones ={" "}
                  {toCurrencyFormat(
                    product.price * quantity +
                      +variations.reduce(
                        (acc, variation) =>
                          acc + variation.addedValue * quantity,
                        0,
                      ),
                  )}
                </Typography>
                <Typography
                  sx={{ fontSize: 12 }}
                  color={"text.secondary"}
                  gutterBottom
                >
                  Notas: {notes ? notes : "Sin notas adicionales"}
                </Typography>
              </Box>
            </Box>
          </CustomBox>
          <Divider sx={{ mb: 0.5 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight={"bold"} sx={{ fontSize: 20 }} gutterBottom>
              Total a pagar:
            </Typography>
            <Typography>
              {toCurrencyFormat(
                product.price * quantity +
                  +variations.reduce(
                    (acc, variation) => acc + variation.addedValue * quantity,
                    0,
                  ),
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Button variant="outlined" onClick={handleBackStep}>
          Atrás
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Agregar
        </Button>
      </Box>
    </Box>
  );
};
