export const DaysOfTheWeek = {
  0: 7,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
};

export type DaysOfTheWeek = (typeof DaysOfTheWeek)[keyof typeof DaysOfTheWeek];
