interface LocalStorageKeys {
  jwtToken: string;
  userId: string;
}

interface LocalStorageInterface {
  keys: LocalStorageKeys;
  getStringWithIdentifier(key: string): string | null;
  setStringWithIdentifier(key: string, value: string): void;
  getObjectWithIdentifier<T>(key: string): T | null;
  setObjectWithIdentifier<T>(key: string, object: T): void;
  removeItemWithIdentifier(key: string): void;
}

export const LocalStorage: LocalStorageInterface = {
  keys: {
    jwtToken: 'JWT_TOKEN',
    userId: 'USER_ID',
  },
  getStringWithIdentifier: (key) => {
    return localStorage.getItem(key);
  },
  setStringWithIdentifier: (key, value) => {
    localStorage.setItem(key, value);
  },
  getObjectWithIdentifier: (key) => {
    return JSON.parse(localStorage.getItem(key) || 'null');
  },
  setObjectWithIdentifier: (key, object) => {
    localStorage.setItem(key, JSON.stringify(object));
  },
  removeItemWithIdentifier: (key) => {
    localStorage.removeItem(key);
  },
};
