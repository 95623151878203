import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

export const FrequentQuestions = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container maxWidth="sm">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: '90vh' }}
      >
        <Grid item>
          <Box
            display="flex"
            justifyContent="center"
            margin={2}
            flexDirection={'column'}
          >
            <Typography textAlign={'center'} sx={{ mt: 1, mb: 1 }} variant="h6">
              Preguntas Frecuentes
            </Typography>
            <Box>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography fontWeight={'bold'}>
                    ¿Cómo puedo hacer un pedido?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Si es tu primera vez usando Chalet App, te recomendamos que
                    te registres para que puedas guardar tus direcciones de
                    entrega y métodos de pago. Una vez que hayas iniciado
                    sesión, puedes comenzar a agregar productos a tu carrito de
                    compras y luego proceder a la pantalla de pago. Una vez que
                    hayas realizado tu pedido, recibirás notificationes de
                    estado de tu pedido.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography fontWeight={'bold'}>
                    ¿Cuál es la zona de cobertura?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Actualmente, Chalet App solo ofrece servicio de entrega a
                    domicilio en Chalatenango, El Salvador. Cubriendo las
                    siguientes zonas:
                  </Typography>
                  <ul>
                    <li>Chalatenango</li>
                    <li>San Miguel de Mercedes</li>
                    <li>Totolco</li>
                    <li>Concepción Quezaltepeque</li>
                    <li>Colonias de Reubicación</li>
                    <li>San Antonio Los Ranchos</li>
                    <li>Guariila</li>
                    <li>Azacualpa</li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography fontWeight={'bold'}>
                    ¿Cuánto es el tiempo de espera de una orden?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography gutterBottom>
                    El tiempo de espera de una orden varía dependiendo del
                    restaurante y la hora del día.
                  </Typography>
                  Usualmente el tiempo de espera es de 30 a 45 minutos.
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography fontWeight={'bold'}>
                    ¿Puedo ordenar de diferentes comercios en una sola orden?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography gutterBottom>
                    Por el momento no es posible ordenar de diferentes comercios
                    en una sola orden.
                  </Typography>
                  <Typography>
                    Pero puedes hacer multiples pedidos de diferentes comercios
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography fontWeight={'bold'}>
                    ¿Puedo pagar con tarjeta?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    No, por el momento solo aceptamos pagos en efectivo.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel6'}
                onChange={handleChange('panel6')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography fontWeight={'bold'}>
                    ¿Puedo ordenar para otra ubicación?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Sí, puedes agregar mulitiples direcciones de entrega.
                    Recuerda asegurarte de seleccionar la dirección correcta
                    antes de realizar tu pedido.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
