import { yupResolver } from '@hookform/resolvers/yup';
import { MailOutline, WhatsApp } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FormEvent, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

// Define el esquema de validación con Yup
const schema = yup.object().shape({
  selectedOption: yup.string().required('Por favor, selecciona una opción'),
  consultation: yup.string().when('selectedOption', {
    is: 'Otra consulta',
    then: (schema: any) => schema.required('es requerido'),
    otherwise: (schema: any) => schema.notRequired(),
  }),
});

type FormData = {
  selectedOption: string;
  consultation?: string;
};

export const ClientService = () => {
  const [consult, setConsult] = useState<string>('');
  const [submitType, setSubmitType] = useState<string | null>(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      selectedOption: '',
      consultation: '',
    },
  });

  const onSubmit = (data: FormData) => {
    if (submitType === 'whatsapp') {
      sendWhatsAppMessage(data);
    } else if (submitType === 'email') {
      sendEmail(data);
    }
  };

  const sendWhatsAppMessage = (data: FormData) => {
    let message = '';
    if (data.selectedOption === 'Otra consulta' && data.consultation) {
      message = data.consultation;
    } else {
      message = data.selectedOption;
    }
    const phoneNumber = '+50372375695';

    const whatsappUrl = `https://api.whatsapp.com/send/?phone=${encodeURIComponent(
      phoneNumber,
    )}&text=${encodeURIComponent(message)}`;

    window.open(whatsappUrl, '_blank');
  };

  const sendEmail = (data: FormData) => {
    let message = '';
    if (data.selectedOption === 'Otra consulta' && data.consultation) {
      message = data.consultation;
    } else {
      message = data.selectedOption;
    }

    const recipientEmail = 'elchaletsv@gmail.com';
    const emailSubject = 'Soporte técnico';

    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
      emailSubject,
    )}&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Container maxWidth="sm">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: '90vh' }}
      >
        <Grid item xs={12} padding={2}>
          <Typography textAlign={'center'} sx={{ mt: 1, mb: 1 }} variant="h6">
            Servicio al cliente
          </Typography>
          <Typography textAlign={'center'} sx={{ mt: 1, mb: 1 }} variant="h6">
            ¿En qué podemos ayudar?
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            flexDirection={'column'}
          >
            <Box>
              <FormControl>
                <Controller
                  name="selectedOption"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setConsult(e.target.value);
                      }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="No he recibido mi pedido"
                        control={<Radio />}
                        label="No he recibido mi pedido"
                      />
                      <FormControlLabel
                        value="Mi pedido llegó incompleto"
                        control={<Radio />}
                        label="Mi pedido llegó incompleto"
                      />
                      <FormControlLabel
                        value="Otra consulta"
                        control={<Radio />}
                        label="Otra consulta"
                      />
                    </RadioGroup>
                  )}
                />
                {errors.selectedOption && (
                  <Typography variant="caption" color={'error'}>
                    {errors.selectedOption.message}
                  </Typography>
                )}
              </FormControl>
            </Box>

            <Box display={'flex'} flexDirection={'column'}>
              {consult === 'Otra consulta' && (
                <FormControl>
                  <Controller
                    name="consultation"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        label="Consulta..."
                      />
                    )}
                  />
                  {errors.consultation && (
                    <Typography variant="caption" color="error">
                      {errors.consultation.message}
                    </Typography>
                  )}
                </FormControl>
              )}
              <Stack
                direction={'row'}
                spacing={1}
                mt={2}
                display={'flex'}
                justifyContent={'center'}
              >
                <Button
                  name="whatsapp"
                  type="submit"
                  variant="contained"
                  color="success"
                  startIcon={<WhatsApp />}
                  // Puedes habilitar o deshabilitar el botón según tu lógica
                  disabled={false}
                  onClick={() => setSubmitType('whatsapp')}
                >
                  Consultar
                </Button>
                <Button
                  name="mail"
                  type="submit"
                  variant="contained"
                  color="info"
                  startIcon={<MailOutline />}
                  // Puedes habilitar o deshabilitar el botón según tu lógica
                  disabled={false}
                  onClick={() => setSubmitType('email')}
                >
                  Consultar
                </Button>
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
