import { Box, Button, Container, Grid, Stack, TextField, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import routesConstants from 'utils/routesConstants';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MaskNumber } from '../Auth/login';
import { toast } from 'react-hot-toast';
import chaletApi from 'api/chaletApi';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { clearLocation } from 'redux/slices/locationSlice';
import { logOut } from 'redux/slices/userAuthSlice';
import { clearAllCarts } from 'redux/slices/cartSlice';

const schema = yup.object().shape({
  phone: yup
    .string()
    .max(9, 'maximo 8 digitos')
    .phone('SV', 'el numero es invalido')
    .required('el numero es requerido'),
});

const codeSchema = yup
  .string()
  .max(4, ' solo pueden ser 4 digitos')
  .min(4, ' solo pueden ser 4 digitos')
  .required('el código es requerido');

export default function DeleteAccount() {
  const navigate = useNavigate()
  const { isLoggedIn } = useAppSelector((state) => state.userAuthSlice);
  const [resendCode] = chaletApi.useLazyResendCodeQuery();
  const [deleteAccount] = chaletApi.useDeleteMyAccountMutation();
  const dispatch = useAppDispatch();

  const [phone, setPhone] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');

  const location = useLocation()

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const code = event.target.value;

    try {
      codeSchema.validateSync(code);
      setError('');
    } catch (error: any) {
      setError(error.message);
    }

    setCode(code);
  };


  const handleResendCode = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      resendCode({ phone })
        .unwrap()
        .then((payload) => {
          if (payload.success) {
            toast.success(payload.data);
          } else {
            toast.error(payload.error);
          }
        })
        .catch((error) => {
          console.error('rejected', error);
          toast.error(error);
        });
    },
    [phone, resendCode],
  );

  const onClick = () => {
    if (location.key === 'default') {
      navigate(routesConstants.initial);
    } else {
      navigate(-1)
    }
  }

  const handleSendCodeSubmit = ({ phone }: { phone: string }) => {
    setPhone(phone)

    resendCode({ phone })
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          setShowVerification(true)
          toast.success(payload.data)
        } else {
          toast.error(payload.error)
        }
      })
      .catch((error) => {
        console.error('rejected', error)
        toast.error(error)
        setShowVerification(false)
      })
  }

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
  });

  const LogOut = async () => {
    try {
      await dispatch(logOut());
      await dispatch(clearLocation());
      await dispatch(clearAllCarts());
      window.location.href = routesConstants.login;
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteAccount = () => {
    const phoneNumber = phone.replace(/-/g, "");

    deleteAccount({ code, phone: phoneNumber })
      .unwrap()
      .then((e) => {
        if (e.success) {
          toast.success('Cuenta eliminada exitosamente')
          LogOut()
        } else {
          toast.error("codigo invalido")
        }
      })
      .catch(e => {
        console.log("error", e)
      })
  }

  return (
    <Container>
      <Button onClick={onClick} aria-label="back" sx={{ mt: 2, ml: 1, mr: 1, gap: 1 }}>
        <ArrowBackIcon fontSize="medium" />
        <Typography fontWeight={600}>
          Regresar
        </Typography>
      </Button>
      <Stack gap={2} m={2} mt={1} minHeight={700}>
        <Typography variant="h4">
          Muchas gracias por ser parte de El Chalet, esperamos que vuelvas pronto.
        </Typography>
        <Typography variant="h5">
          Todos tus datos personales, incluyendo direcciones serán eliminados.
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6} component="form" onSubmit={handleSubmit(handleSendCodeSubmit)}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  disabled={showVerification}
                  type="tel"
                  inputMode="numeric"
                  label="Teléfono"
                  fullWidth
                  sx={{ mb: 1.5 }}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  onChange={(event) => {
                    const { value } = event.target;
                    field.onChange(MaskNumber(value)); // Actualiza el valor del campo
                  }}
                  value={field.value} // Asigna el valor del campo
                />
              )}
            />
            <Button
              variant="contained"
              disabled={showVerification}
              fullWidth
              type='submit'
            >
              Enviar codigo de verificación
            </Button>
            <Typography variant="caption">
              Un codigo de verificación será enviado a tu número de teléfono para confirmar tu identidad.
            </Typography>
          </Grid>
          <Grid paddingTop={2} xs={12} />
          <Grid item xs={12} md={6} component="form">
            {
              showVerification && (
                <Box>
                  <Box display={'flex'}>
                    <TextField
                      type="number"
                      label="Código de verificación"
                      fullWidth
                      value={code}
                      onChange={handleCodeChange}
                      error={!!error}
                      helperText={error}
                    />

                    <Button sx={{ m: 1 }} variant="text" onClick={handleResendCode}>
                      Reenviar
                    </Button>
                  </Box>
                  <Typography variant="body2" mt={1}>
                    Ingresa el código de verificación para eliminar tu cuenta.
                  </Typography>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 3 }}
                    disabled={!!error}
                    onClick={handleDeleteAccount}
                  >
                    Eliminar mi cuenta
                  </Button>
                </Box>
              )
            }

          </Grid>
        </Grid>
      </Stack>
    </Container>
  )
}
