import React, { useEffect, useState } from "react";
import {
  Divider,
  styled,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
} from "@mui/material";

import chaletApi from "api/chaletApi";
import { InitialProductVariation } from "./InitialProductVariation";
import { BranchById, Product } from "types/Branch";
import { AddOrderDetailPreview } from "./AddOrderDetailPreview";
import { BranchMenuCategory, Item } from "types/Branch";

type AddOrderDetailProps = {
  branchId: number;
  orderId: number;
};

type MenuPanelProps = {
  MenuCategories: BranchMenuCategory[];
  orderId: number;
  branch: BranchById | undefined;
};

type ProductListProps = {
  products: Item[];
  handleNextStep: (product: Product) => void;
};

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 8,
  position: "relative",
  [theme.breakpoints.down("md")]: {
    "& img": {
      width: 125,
      height: 125,
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& img": {
      width: 80,
      height: 80,
    },
    "& .MuiTypography-root": {
      fontSize: "1rem",
    },
    "& .MuiBox-root": {
      margin: 3,
    },
  },
}));

const ProductList: React.FC<ProductListProps> = ({
  products,
  handleNextStep,
}) => {
  const handleAddToOrder = (product: Product) => {
    handleNextStep(product);
  };

  if (products) {
    return (
      <div style={{ overflowX: "auto" }}>
        {products.map((product) => (
          <Box key={product.id}>
            <CustomBox>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {product.product.image && (
                  <Box mr={1}>
                    <img
                      width={75}
                      height={75}
                      src={product.product.image}
                      style={{
                        borderRadius: "5px",
                        aspectRatio: "1/1",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                )}
                <Box display={"flex"} flexDirection={"column"} flex={1}>
                  <Typography style={{ fontWeight: "bold", fontSize: 16 }}>
                    {product.product.name}
                  </Typography>
                  <Typography style={{ fontSize: 15 }}>
                    {product.product.description}
                  </Typography>
                </Box>
              </Box>
              <Button
                onClick={() => handleAddToOrder(product.product)}
                variant="contained"
                color="primary"
              >
                Agregar
              </Button>
            </CustomBox>

            <Divider sx={{ mb: 0.5 }} />
          </Box>
        ))}
      </div>
    );
  }

  return null;
};

const MenuPanel: React.FC<MenuPanelProps> = ({
  MenuCategories,
  orderId,
  branch,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({} as Product);
  const [selectedVariation, setSelectedVariation] = useState({} as any);
  const [quantity, setQuantity] = useState(1);
  const [notes, setNotes] = useState<string>("");

  const handleNextStep = (product: Product) => {
    setSelectedProduct(product);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleResetStep = () => {
    setActiveStep(0);
  };

  const handleNextVariationStep = (
    variation: any,
    quantity: number,
    notes: string,
  ) => {
    setSelectedVariation(variation);

    setQuantity(quantity);

    setNotes(notes);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step key="ProductListStep">
          <StepLabel>Lista de productos</StepLabel>
        </Step>
        <Step key="OrderDetailsStep">
          <StepLabel>Detalles de la orden</StepLabel>
        </Step>
        <Step key="OrderDetailsPrevStep">
          <StepLabel>Previsualización de la orden</StepLabel>
        </Step>
      </Stepper>
      {activeStep === 0 && (
        <Box sx={{ margin: "20px" }}>
          {MenuCategories.map(
            (menuCategory, i) =>
              menuCategory.items.length > 0 && (
                <Box
                  sx={{
                    margin: "20px",
                  }}
                  key={i}
                >
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {menuCategory.name}
                  </Typography>
                  <ProductList
                    products={menuCategory.items}
                    handleNextStep={handleNextStep}
                  />
                </Box>
              ),
          )}
        </Box>
      )}
      {activeStep === 1 && (
        <InitialProductVariation
          productId={selectedProduct.id}
          handleNextStep={handleNextVariationStep}
          handleBackStep={handleBackStep}
        />
      )}
      {activeStep === 2 && branch && (
        <AddOrderDetailPreview
          handleBackStep={handleBackStep}
          handleResetStep={handleResetStep}
          variations={selectedVariation}
          product={selectedProduct}
          quantity={quantity}
          notes={notes}
          orderId={orderId}
          branch={branch}
        />
      )}
    </>
  );
};

export const AddOrderDetailStep: React.FC<AddOrderDetailProps> = ({
  branchId,
  orderId,
}) => {
  const [getBranch, result] = chaletApi.useLazyGetBranchByIdQuery();

  useEffect(() => {
    getBranch(branchId);
  }, [branchId]);

  if (result.data) {
    return (
      <>
        {result.data.branchMenus.map((branchMenu) => {
          return (
            <MenuPanel
              key={branchMenu.id}
              MenuCategories={branchMenu.categories}
              orderId={orderId}
              branch={result.data}
            />
          );
        })}
      </>
    );
  }

  return null;
};
