import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import chaletApi from '../../../../api/chaletApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone-lite';
import { min } from 'date-fns';
import { useAppSelector } from 'redux/hook';
import routesConstants from 'utils/routesConstants';

type VerificationProps = {
  phone: string;
  isRegister?: boolean;
};

const codeSchema = yup
  .string()
  .max(4, ' solo pueden ser 4 digitos')
  .min(4, ' solo pueden ser 4 digitos')
  .required('el código es requerido');

type FormValues = {
  code: string;
};

export const VerificationCode: React.FC<VerificationProps> = ({
  phone,
  isRegister,
}) => {
  const [verifyCode] = chaletApi.useLazyVerifyCodeQuery();
  const [loginWithToken] = chaletApi.useLazyLoginWithTokenQuery();
  const [resendCode] = chaletApi.useLazyResendCodeQuery();
  const [createAddress] = chaletApi.useSaveUserAddressMutation();
  const [updateLastLocationId] = chaletApi.useUpdateLastLocationIdMutation();

  const location = useAppSelector((state) => state.locationSlice);
  const [verificationCode, setVerificationCode] = useState('');
  const navigate = useNavigate();
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { uniqueId, deviceCart } = useAppSelector((state) => state.device);

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const code = event.target.value;

    try {
      codeSchema.validateSync(code);
      setError('');
    } catch (error: any) {
      setError(error.message);
    }

    setCode(code);
  };

  console.log(deviceCart);

  const handleVerificationSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      verifyCode({ phone, code })
        .unwrap()
        .then((payload) => {
          if (payload.success) {
            const token = payload.data.token;

            loginWithToken({ token, uniqueId, carts: deviceCart })
              .unwrap()
              .then((payload) => {
                if (payload.success) {
                  if (isRegister === true) {
                    if (location) {
                      const addressData = {
                        name: location.name,
                        address: location.address,
                        reference: location.reference,
                        latitude: location.location?.coordinates[1],
                        longitude: location.location?.coordinates[0],
                      };
                      createAddress(addressData)
                        .unwrap()
                        .then((payload) => {
                          updateLastLocationId(payload.data.id);
                        });
                    }
                  }

                  toast.success('has iniciado sesión correctamente');
                  navigate(routesConstants.initial);
                } else {
                  toast.error('Ups! ha ocurrido un error, intentalo de nuevo');
                }
              })
              .catch((error) => {
                toast.error(`Ha ocurrido un error: ${error}`);
              });
          } else {
            toast.error('Código incorrecto');
          }
        })
        .catch((error) => {
          console.error('rejected', error);
          toast.error(error);
        });
    },
    [code, phone],
  );

  const handleResendCode = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      resendCode({ phone })
        .unwrap()
        .then((payload) => {
          if (payload.success) {
            toast.success(payload.data);
          } else {
            toast.error(payload.error);
          }
        })
        .catch((error) => {
          console.error('rejected', error);
          toast.error(error);
        });
    },
    [phone, resendCode],
  );

  return (
    <Box>
      <Box display={'flex'}>
        <TextField
          type="number"
          label="Código de verificación"
          fullWidth
          value={code}
          onChange={handleCodeChange}
          error={!!error}
          helperText={error}
        />

        <Button sx={{ m: 1 }} variant="text" onClick={handleResendCode}>
          Reenviar
        </Button>
      </Box>
      <Typography variant="body2" mt={1}>
        Código enviado, podría tardarse un minuto en llegar.
      </Typography>
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 1, mb: 3 }}
        disabled={!!error}
        onClick={handleVerificationSubmit}
      >
        Verificar Código
      </Button>
    </Box>
  );
};
