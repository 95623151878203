import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { toCurrencyFormat } from 'utils/formatter';
import { Item, VariationOption } from 'types/UserCart';

type ProductVariationViewProps = {
  product: Item;
};

export const ProductVariationView: React.FC<ProductVariationViewProps> = ({
  product,
}) => {
  const formatOption = (option: VariationOption) => {
    return option.addedValue === 0
      ? `${option.value}`
      : `${option.value} +(${toCurrencyFormat(option.addedValue)})`;
  };

  const formattedVariations = product.variations.map((variation) => {
    const option = variation.option;

    const options = formatOption(option);

    return (
      <Stack key={variation.id} direction={'row'} spacing={0.5}>
        <Typography
          sx={{ fontSize: 12 }}
          fontWeight={'bold'}
          color={'text.secondary'}
        >
          {variation.option.variation.name}:
        </Typography>
        <Typography sx={{ fontSize: 12 }} color={'text.secondary'}>
          {options}
        </Typography>
      </Stack>
    );
  });

  return <Box>{formattedVariations}</Box>;
};
