import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  IconButton,
} from '@mui/material';
import chaletApi from '../../../../../api/chaletApi';
import { addLocation } from '../../../../../redux/slices/locationSlice';
import { useAppDispatch } from 'redux/hook';
import { address } from 'types/Address';
import {
  DeleteOutline,
  EditOutlined,
  LocationOn,
  LocationOnOutlined,
} from '@mui/icons-material';
import toast from 'react-hot-toast';

type AddressCardProps = {
  address: address;
  onEditClick: () => void;
  lastLocationId: number;
  onUpdateLastLocationId: (newId: number) => void;
  handleClose: () => void;
};

export const AddressCard: React.FC<AddressCardProps> = ({
  address,
  onEditClick,
  lastLocationId,
  onUpdateLastLocationId,
  handleClose,
}) => {
  const [deleteAddress] = chaletApi.useDeleteUserAddressMutation();
  const [updateLastLocationId] = chaletApi.useUpdateLastLocationIdMutation();
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    if (address.id === lastLocationId) {
      toast.error('No puedes eliminir la dirección seleccionada');
    } else {
      deleteAddress(address.id);
    }
  };

  const handleChangeLocation = () => {
    const addressData = {
      id: address.id,
      name: address.name,
      address: address.address,
      reference: address.reference,
      location: {
        coordinates: [
          address.location.coordinates[0],
          address.location.coordinates[1],
        ] as [number, number],
      },
      sectorId: address.sectorId,
    };

    toast.promise(
      updateLastLocationId(addressData.id).then(() => {
        onUpdateLastLocationId(addressData.id);
        dispatch(addLocation(addressData)), handleClose();
      }),
      {
        loading: 'Guardando...',
        success: <b>Direccion Actualizada</b>,
        error: <b>Ha ocurrido un error</b>,
      },
    );
  };

  return (
    <Card sx={{ mt: 1 }} elevation={3}>
      <Box display={'flex'}>
        <CardActionArea onClick={handleChangeLocation}>
          <CardContent>
            {lastLocationId === address.id ? (
              <Box display={'flex'} alignItems={'center'}>
                <LocationOn color="primary" sx={{ mr: 1 }} />
                <Box>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {address.name}
                  </Typography>
                  <Typography variant="body1" fontWeight={'bold'} fontSize={12}>
                    {address.address}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box display={'flex'} alignItems={'center'}>
                <LocationOnOutlined sx={{ mr: 1 }} />
                <Box>
                  <Typography variant="body1">{address.name}</Typography>
                  <Typography variant="body1" fontSize={12}>
                    {address.address}
                  </Typography>
                </Box>
              </Box>
            )}
          </CardContent>
        </CardActionArea>
        <CardActions>
          <IconButton size="small" onClick={onEditClick}>
            <EditOutlined />
          </IconButton>
          <IconButton size="small" onClick={handleDelete}>
            <DeleteOutline />
          </IconButton>
        </CardActions>
      </Box>
    </Card>
  );
};
