import { UnfoldLess } from '@mui/icons-material';
import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Location } from 'types/Location';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || '';

const containerStyle = {
  marginTop: '15px',
  display: 'flex',
  height: '300px',
};

type RouteMapProps = {
  origin?: Location;
  destination?: Location | null;
  handleRoute?: (route: any) => void;
  markers?: Location[];
};

export const RouteMap: React.FC<RouteMapProps> = ({
  origin,
  destination,
  handleRoute,
  markers,
}) => {
  const [route, setRoute] = useState<any>();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markersRef, setMarkersRef] = useState<google.maps.Marker[]>([]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const handleOnLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (map && markers) {
      // Elimina los marcadores antiguos
      markersRef.forEach((marker) => marker.setMap(null));
      setMarkersRef([]);

      const bounds = new google.maps.LatLngBounds();
      const newMarkers: google.maps.Marker[] = []; // Almacena los nuevos marcadores

      markers.forEach((marker) => {
        bounds.extend({
          lat: marker.coordinates[1],
          lng: marker.coordinates[0],
        });

        // Crea y guarda el nuevo marcador
        const newMarker = new window.google.maps.Marker({
          position: {
            lat: marker.coordinates[1],
            lng: marker.coordinates[0],
          },
          icon: marker.icon ? { url: marker.icon } : undefined,
          map,
        });

        newMarkers.push(newMarker);
      });

      map.fitBounds(bounds);
      setMarkersRef(newMarkers);
    }
  }, [map, markers]);

  useEffect(() => {
    if (isLoaded && !loadError && destination && origin) {
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: {
            lat: origin.coordinates[1],
            lng: origin.coordinates[0],
          },
          destination: {
            lat: destination.coordinates[1],
            lng: destination.coordinates[0],
          },
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === 'OK') {
            setRoute(result);

            if (handleRoute) {
              handleRoute(result);
            }
          } else {
            console.error('Error calculating route:', status);
          }
        },
      );
    }
  }, [isLoaded, loadError, origin, destination]);

  /*  const handleOnLoad = (map: google.maps.Map) => {
    if (markers) {
      console.log('actualizando', markers);
      const bounds = new google.maps.LatLngBounds();
      markers.forEach((marker) =>
        bounds.extend({
          lat: marker.coordinates[1],
          lng: marker.coordinates[0],
        }),
      );
      map.fitBounds(bounds);

      markers.forEach((marker) => {
        new window.google.maps.Marker({
          position: {
            lat: marker.coordinates[1],
            lng: marker.coordinates[0],
          },
          icon: marker.icon ? { url: marker.icon } : undefined,
          map,
        });
      });
    }
  }; */

  if (loadError)
    return <div>Error loading Google Maps: {loadError.message}</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      options={{
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
      }}
      onLoad={handleOnLoad}
    >
      {route && (
        <DirectionsRenderer
          directions={route}
          options={{
            polylineOptions: {
              strokeColor: '#ef3d32',
              strokeWeight: 5,
            },
          }}
        />
      )}
    </GoogleMap>
  );
};

/*   const center = useMemo(() => {
   
    if (destination) {
      return {
        //When destination provided from ConfirmOrder
        lat: destination.coordinates[1],
        lng: destination.coordinates[0],
      };
    } else {
      return { lat: 0, lng: 0 };
    }
  }, [destination]); */
