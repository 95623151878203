import { createSlice } from '@reduxjs/toolkit';
import chaletApi from '../../api/chaletApi';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

type DeviceState = {
  uniqueId: string;
  deviceCart: { branchId: number; id: number }[];
  sectorId: number | null;
};

const initialState: DeviceState = {
  uniqueId: '',
  deviceCart: [],
  sectorId: null,
};

const devicePersistConfig = {
  key: 'device',
  storage: storage,
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    removeDevice: (state) => {
      state.uniqueId = '';
      state.deviceCart = [];
      state.sectorId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chaletApi.endpoints.getDevice.matchFulfilled,
      (state, { payload }) => {
        if (payload.success) {
          state.uniqueId = payload.data.uniqueId;
        }
      },
    );
    builder.addMatcher(
      chaletApi.endpoints.findAndSetDeviceSector.matchFulfilled,
      (state, { payload }) => {
        if (payload.success) {
          state.sectorId = payload.data.sectorId;
        }
      },
    );
    builder.addMatcher(
      chaletApi.endpoints.getCartByDevice.matchFulfilled,
      (state, { payload }) => {
        if (payload.userCart) {
          const { branchId, id } = payload.userCart;
          if (
            !state.deviceCart.some(
              (item) => item.branchId === branchId && item.id === id,
            )
          ) {
            state.deviceCart.push({ branchId, id });
          }
        }
      },
    );
  },
});

const persistedDeviceSlice = persistReducer(
  devicePersistConfig,
  deviceSlice.reducer,
);

export const { removeDevice } = deviceSlice.actions;

export default persistedDeviceSlice;
