import { Avatar, Button, Grid, Typography, styled } from '@mui/material';

export const CustomAvatar = styled(Avatar)(({ theme }) => ({
  border: '4px solid #ffffff',
  width: '150px',
  height: '150px',
  display: 'flex',
  marginTop: '-75px',
  [theme.breakpoints.down('sm')]: {
    width: '90px',
    height: '90px',
    marginTop: '-50px',
  },
}));

export const CustomGrid = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '10px',
  paddingRight: '10px',

  justifyItems: 'center',
  /*   [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  }, */
}));

export const CustomTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  /*   [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  }, */
}));

export const imgStyle: any = {
  aspectRatio: '16/9',
  objectFit: 'cover',
  width: '100%',
  maxHeight: '200px',
  height: 'auto',
};

export const ButtonCart = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    bottom: 5,
    left: 5,
    right: 5,

    zIndex: 99,
  },
  [theme.breakpoints.up('sm')]: {
    position: 'sticky',
    width: '400px',
    bottom: 5,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));
