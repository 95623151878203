import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import chaletApi from '../../api/chaletApi';
import { User } from 'types/User';

type UserState = {
  user: any | null;
};

const initialState: UserState = {
  user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      chaletApi.endpoints.login.matchFulfilled,
      (
        state,
        action: PayloadAction<{ success: boolean; data: { user: any } }>,
      ) => {
        if (action.payload.success) {
          state.user = action.payload.data.user;
        }
      },
    );
    builder.addMatcher(
      chaletApi.endpoints.getUserById.matchFulfilled,
      (state, action: PayloadAction<User>) => {
        if (action.payload) {
          state.user = action.payload;
        }
      },
    );
  },
});

//export const {} = userSlice.actions;

export default userSlice;
