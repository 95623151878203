import { createSlice } from '@reduxjs/toolkit';

type ModalState = {
  isAddCategoryModalOpen: boolean;
  isAddProductModalOpen: boolean;
  isEditProductModalOpen: boolean;
  isEditUserModalOpen: boolean;
};

const initialState: ModalState = {
  isAddCategoryModalOpen: false,
  isAddProductModalOpen: false,
  isEditProductModalOpen: false,
  isEditUserModalOpen: false,
};

export const modalSlice = createSlice({
  name: 'modal-slice',
  initialState,
  reducers: {
    openAddCategoryModal: (state) => {
      state.isAddCategoryModalOpen = true;
    },
    closeAddCategoryModal: (state) => {
      state.isAddCategoryModalOpen = false;
    },
    openAddProductModal: (state) => {
      state.isAddProductModalOpen = true;
    },
    closeAddProductModal: (state) => {
      state.isAddProductModalOpen = false;
    },
    openEditProductModal: (state) => {
      state.isEditProductModalOpen = true;
    },
    closeEditProductModal: (state) => {
      state.isEditProductModalOpen = false;
    },
    openEditUserModal: (state) => {
      state.isEditUserModalOpen = true;
    },
    closeEditUserModal: (state) => {
      state.isEditUserModalOpen = false;
    },
  },
});

export const {
  openAddCategoryModal,
  closeAddCategoryModal,
  openAddProductModal,
  closeAddProductModal,
  openEditProductModal,
  closeEditProductModal,
  openEditUserModal,
  closeEditUserModal,
} = modalSlice.actions;

export default modalSlice;
