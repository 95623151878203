import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ChaletApiResponse } from 'types/ChaletApiResponse';
import { LoginResponse } from 'types/Login';
import { ShopMenuCategory } from 'types/ShopMenuCategory';
import { Shop } from 'types/Shop';
import { FetchNearShopBody } from 'types/FetchNearShopBody';
import { Product } from 'types/Product';
import { ProductCategory } from 'types/ProductCategory';
import { ProductType } from 'types/ProductTypes';
import { ProductType as ProductTypeResponse } from 'types/ProductType';
import {
  Order,
  OrdersPaginationResponse,
  ProductVariation,
  VariationOption,
} from 'types/Order';
import { OrderProcess, OrderProcessStep } from 'types/OrderProcess';
import { ShopMenus } from 'types/ShopMenu';
import { User } from 'types/User';
import { DeliveryOptions } from 'types/DeliveryOptions';
import { PostOrderBody } from 'types/PostOrder';
import { isStoreOpen } from 'utils/store';
import { address } from 'types/Address';
import { CreateShopWithUserRequest } from 'types/Requests/CreateShopWithUserRequest';
import { CreateShopWithUserResponse } from 'types/Responses/CreateShopWithUserResponse';
import { Branch, BranchById, BranchWithTodayScheduleUI } from 'types/Branch';
import {
  transformHomePageBranchesWithTodaySchedule,
  transformProductCategoryWithTodaySchedule,
  transformProductTypeWithTodaySchedule,
} from 'utils/branchSchedule';
import { Sector, settings } from 'types/Sectors';

export type NearShop = Shop & {
  isClosedToday: boolean;
  opensAt: number;
  closesAt: number;
  openDate: Date;
  closeDate: Date;
  categories: [
    {
      id: number;
      name: string;
      image: string;
    },
  ];
};

const chaletApi = createApi({
  reducerPath: 'chaletApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders(headers, api) {
      const store: any = api.getState();
      headers.set('Authorization', `Bearer ${store.userAuthSlice.token}`);
      headers.set('deviceid', store.device.uniqueDeviceId || '');
    },
  }),
  tagTypes: [
    'orders',
    'shop',
    'shops',
    'shop-menus',
    'shop-products',
    'address',
    'OneOrder',
    'Orders',
    'branch',
    'carts',
    'user',
  ],
  endpoints: (builder) => ({
    //Login
    login: builder.mutation<
      ChaletApiResponse<LoginResponse>,
      { email: string; password: string }
    >({
      query: ({ email, password }) => ({
        method: 'POST',
        url: '/auth/login',
        body: { email, password },
      }),
    }),
    loginWithToken: builder.query<
      ChaletApiResponse<LoginResponse>,
      {
        token: string;
        uniqueId: string;
        carts: { branchId: number; id: number }[];
      }
    >({
      query: ({ token, uniqueId, carts }) => ({
        method: 'POST',
        url: '/auth/login/token',
        body: { token, uniqueId, carts },
      }),
    }),
    resendCode: builder.query({
      query: ({ phone }) => ({
        method: 'POST',
        url: '/auth/resend-code',
        body: { phone },
      }),
    }),
    verifyCode: builder.query({
      query: ({ code, phone }) => ({
        method: 'POST',
        url: '/auth/verify-code',
        body: { code, phone },
      }),
    }),
    userRegister: builder.query({
      query: ({ name, phone, uniqueId, carts }) => ({
        method: 'POST',
        url: '/auth/register',
        body: { name, phone, uniqueId, carts },
      }),
    }),

    //Orders
    createOrder: builder.mutation({
      query: ({
        cartId,
        userId,
        shopId,
        branchId,
        addressId,
        addressLocation,
        address,
        addressReference,
        orderDetails,
        orderProcessId,
        currentOrderProcessStepId,
        deliveryPrice,
      }) => ({
        method: 'POST',
        url: `/orders/`,
        body: {
          cartId,
          userId,
          shopId,
          branchId,
          addressId,
          addressLocation,
          address,
          addressReference,
          details: orderDetails,
          orderProcessId,
          currentOrderProcessStepId,
          deliveryPrice,
        },
      }),
      invalidatesTags: ['Orders'],
    }),

    getActiveOrders: builder.query({
      query: (shopId) => ({
        method: 'GET',
        url: '/orders/active',
        params: { shopId, withorderProcesses: true },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.data.map(({ id }: any) => ({ type: 'orders', id })),
              'orders',
            ]
          : ['orders'],
    }),
    getOrdersInRange: builder.query({
      query: ({ from, to, shopId }) => ({
        method: 'GET',
        url: '/orders/all-in-dates',
        params: { from, to, shopId },
      }),
    }),
    getOrder: builder.query({
      query: (orderId) => ({
        method: 'GET',
        url: `/orders/${orderId}`,
      }),
      providesTags: ['OneOrder'],
    }),
    getOrders: builder.query<
      OrdersPaginationResponse,
      { page?: number; limit?: number }
    >({
      query: (queryArgs) => {
        const { page, limit } = queryArgs;
        return {
          method: 'GET',
          url: `/orders`,
          params: {
            page,
            limit,
          },
        };
      },
      providesTags: ['Orders'],
      transformResponse: (
        response: ChaletApiResponse<OrdersPaginationResponse>,
      ) => {
        return response.data;
      },
    }),

    getOneOrder: builder.query<Order, number>({
      query: (orderId) => ({
        method: 'GET',
        url: `orders/${orderId}`,
      }),
      providesTags: ['OneOrder'],
      transformResponse: (response: ChaletApiResponse<Order>, meta, arg) =>
        response.data,
    }),
    getCurrentOrderProcessStep: builder.query<OrderProcessStep, number>({
      query: (stepId) => ({
        method: 'GET',
        url: `order-process-steps/${stepId}`,
      }),
      providesTags: ['OneOrder'],
      transformResponse: (
        response: ChaletApiResponse<OrderProcessStep>,
        meta,
        arg,
      ) => response.data,
    }),

    cancelOrder: builder.mutation({
      query: (body) => ({
        method: 'PUT',
        url: `orders/cancel`,
        body: body,
      }),
    }),

    //Users
    getUserById: builder.query<User, number>({
      query: (userId) => ({
        method: 'GET',
        url: `/users/${userId}`,
      }),
      transformResponse: (response: ChaletApiResponse<User>, meta, arg) =>
        response.data,
      providesTags: ['user'],
    }),
    getUserAddress: builder.query({
      query: () => ({
        method: 'GET',
        url: `/user-addresses/`,
      }),
      providesTags: ['address'],
    }),

    getOneUserAddress: builder.query<address, number>({
      query: (id) => ({
        method: 'GET',
        url: `/user-addresses/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<address>, meta, arg) =>
        response.data,
    }),

    saveUserAddress: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: `/user-addresses/`,
        body,
      }),
      invalidatesTags: ['address'],
    }),
    updateUserAddress: builder.mutation({
      query: (addressData) => ({
        method: 'PUT',
        url: `/user-addresses/${addressData.id}`,
        body: addressData,
      }),
      invalidatesTags: ['address'],
    }),
    deleteUserAddress: builder.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/user-addresses/${id}`,
      }),
      invalidatesTags: ['address'],
    }),

    updateLastLocationId: builder.mutation({
      query: (id) => ({
        method: 'PUT',
        url: `/users/update/last-location`,
        body: { config: { lastLocationId: id } },
      }),
    }),

    //Shops

    registerShopWithUser: builder.mutation<
      CreateShopWithUserResponse,
      CreateShopWithUserRequest
    >({
      query: (body) => ({
        method: 'POST',
        url: `/users/register-with-shop`,
        body,
      }),
      transformResponse: (
        response: ChaletApiResponse<CreateShopWithUserResponse>,
        meta,
        arg,
      ) => response.data,
    }),
    getBranchById: builder.query<BranchById, number>({
      query: (branchId) => ({
        method: 'GET',
        url: `/branches/${branchId}`,
      }),
      providesTags: ['branch'],
      transformResponse: (response: ChaletApiResponse<BranchById>) => {
        return response.data;
      },
    }),
    getShopById: builder.query({
      query: (shopId) => ({
        method: 'GET',
        url: `/shops/${shopId}`,
      }),
      providesTags: ['shop'],
      transformResponse: (response: ChaletApiResponse<Shop>, meta, arg) =>
        response.data,
    }),
    getAllShops: builder.query({
      query: () => ({
        method: 'GET',
        url: `/shops/all`,
      }),
      providesTags: ['shops'],
    }),
    getShopMenusForShop: builder.query<ShopMenus[], number>({
      query: (shopId) => ({
        method: 'GET',
        url: `/shop-menus/forShop/${shopId}`,
      }),
      providesTags: ['shop-menus'],
      transformResponse: (
        response: ChaletApiResponse<ShopMenus[]>,
        meta,
        arg,
      ) => response.data,
    }),
    saveMenuCategory: builder.mutation<
      ShopMenuCategory,
      Partial<ShopMenuCategory>
    >({
      query: (body) => ({
        method: 'POST',
        url: `/shop-menu-categories`,
        body,
      }),
      invalidatesTags: ['shop-menus'],
      transformResponse: (
        response: ChaletApiResponse<ShopMenuCategory>,
        meta,
        arg,
      ) => response.data,
    }),
    getNearShops: builder.mutation<NearShop[], FetchNearShopBody>({
      query: (body) => ({
        method: 'POST',
        url: `/shops/near?type=${body.type}`,
        body,
      }),
      transformResponse: (
        response: ChaletApiResponse<NearShop[]>,
        meta,
        arg,
      ) => {
        // Separar las tiendas en tres grupos: abiertas, no abiertas pero no cerradas hoy, y cerradas hoy
        const openShops = [];
        const notOpenShops = [];
        const closedShops = [];

        for (const shop of response.data) {
          if (shop.isClosedToday) {
            closedShops.push(shop); // Tienda cerrada hoy
          } else if (isStoreOpen(shop)) {
            openShops.push(shop); // Tienda abierta
          } else {
            notOpenShops.push(shop); // Tienda no abierta pero no cerrada hoy
          }
        }

        // Ordenar las tiendas abiertas primero por si ya han abierto o no y luego por "id"
        openShops.sort((a, b) => {
          const isOpenA = isStoreOpen(a);
          const isOpenB = isStoreOpen(b);

          if (isOpenA && !isOpenB) {
            return -1; // "a" (abierta y ya ha abierto) primero
          } else if (!isOpenA && isOpenB) {
            return 1; // "b" (abierta y ya ha abierto) primero
          } else {
            return a.id - b.id; // Ordenar por "id" si el estado de apertura es igual
          }
        });

        // Ordenar las tiendas no abiertas pero no cerradas hoy por "id"
        notOpenShops.sort((a, b) => a.id - b.id);

        // Ordenar las tiendas cerradas hoy por "id"
        closedShops.sort((a, b) => a.id - b.id);

        // Combinar las tiendas en un solo arreglo, con las abiertas primero, luego las no abiertas y finalmente las cerradas
        const sortedShops = openShops.concat(notOpenShops, closedShops);

        return sortedShops;
      },
    }),

    //Products
    getProductsForCategoryMenu: builder.query<Product[], number>({
      query: (categoryMenuId) => ({
        method: 'GET',
        url: `/shop-products/forCategoryMenu/${categoryMenuId}`,
      }),
      transformResponse: (response: ChaletApiResponse<Product[]>, meta, arg) =>
        response.data,
      providesTags: (result) => {
        if (!result) return ['shop-menus'];
        return result.length > 0
          ? result.map(({ shopMenuCategoryId }) => ({
              type: 'shop-menus',
              id: shopMenuCategoryId,
            }))
          : ['shop-menus'];
      },
    }),
    getProductCategories: builder.query<
      ProductCategory[],
      ProductType | undefined
    >({
      query: (type = ProductType.Food) => ({
        method: 'GET',
        url: `/product-categories?type=` + type,
      }),
      transformResponse: (
        response: ChaletApiResponse<ProductCategory[]>,
        meta,
        arg,
      ) => response.data,
    }),
    saveShopProduct: builder.mutation<Product, Partial<Product>>({
      query: (body) => ({
        method: 'POST',
        url: `/shop-products`,
        body,
      }),
      invalidatesTags: (result) => {
        return result
          ? [{ type: 'shop-products', id: result.id }]
          : ['shop-products'];
      },
      transformResponse: (response: ChaletApiResponse<Product>, meta, arg) =>
        response.data,
    }),
    getProductById: builder.query<Product, number>({
      query: (id) => ({
        method: 'GET',
        url: `/shop-products/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<Product>, meta, arg) =>
        response.data,
      providesTags: (result) => {
        return result?.id
          ? [{ type: 'shop-products', id: result.id }]
          : ['shop-products'];
      },
    }),
    editShopProduct: builder.mutation<Product, Partial<Product>>({
      query: (body) => ({
        method: 'PUT',
        url: `/shop-products`,
        body,
      }),
      invalidatesTags: (result) => {
        return result
          ? [{ type: 'shop-products', id: result.id }]
          : ['shop-products'];
      },
      transformResponse: (response: ChaletApiResponse<Product>, meta, arg) =>
        response.data,
    }),
    saveProductVariation: builder.mutation<
      ProductVariation,
      Partial<ProductVariation>
    >({
      query: (body) => ({
        method: 'POST',
        url: `/product-variations`,
        body,
      }),
      invalidatesTags: (result) => {
        return result
          ? [{ type: 'shop-products', id: result.shopProductId }]
          : ['shop-products'];
      },
      transformResponse: (
        response: ChaletApiResponse<ProductVariation>,
        meta,
        arg,
      ) => response.data,
    }),
    saveProductVariationOption: builder.mutation<
      VariationOption,
      Partial<VariationOption>
    >({
      query: (body) => ({
        method: 'POST',
        url: `/product-variation-options`,
        body,
      }),
      invalidatesTags: (result) => {
        return ['shop-products'];
      },
      transformResponse: (
        response: ChaletApiResponse<VariationOption>,
        meta,
        arg,
      ) => response.data,
    }),
    getOrderProcesses: builder.query<OrderProcess[], void>({
      query: () => ({
        method: 'GET',
        url: `/order-processes`,
      }),
      transformResponse: (
        response: ChaletApiResponse<OrderProcess[]>,
        meta,
        arg,
      ) => response.data,
    }),
    getOneOrderProcess: builder.query<OrderProcess, number>({
      query: (id) => ({
        method: 'GET',
        url: `/order-processes/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<OrderProcess>) =>
        response.data,
    }),

    getDeliveryOptions: builder.query<DeliveryOptions, void>({
      query: () => ({
        method: 'GET',
        url: `/app/delivery`,
      }),
      transformResponse: (
        response: ChaletApiResponse<DeliveryOptions>,
        meta,
        ard,
      ) => response.data,
    }),
    updateUser: builder.mutation({
      query: ({ userId, body }) => ({
        method: 'PUT',
        url: `/users/${userId}`,
        body,
      }),
      invalidatesTags: ['user'],
    }),
    getHomepage: builder.query({
      query: ({ sectorId, userAddressId, lat, lng, uniqueId, day }) => ({
        method: 'GET',
        url: `/homepage/home`,
        params: { userAddressId, lat, lng, sectorId, uniqueId, day },
      }),
      transformResponse: transformHomePageBranchesWithTodaySchedule,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.userAddressId}`;
      },
    }),
    getDevice: builder.mutation({
      query: (body = null) => ({
        method: 'POST',
        url: `/devices`,
        ...(body && { body }),
      }),
    }),
    findAndSetDeviceSector: builder.mutation({
      query: ({ latitude, longitude, fcmToken, uniqueId }) => ({
        method: 'POST',
        url: `/sectors/set-device-sector`,
        body: { latitude, longitude, fcmToken, uniqueId },
      }),
    }),
    getCartByUser: builder.query({
      query: (id) => ({
        method: 'GET',
        url: `/branches/cart/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<Branch>, meta, arg) =>
        response.data,
      providesTags: ['carts'],
    }),
    getCartByDevice: builder.query({
      query: ({ id, uniqueId }) => ({
        method: 'GET',
        url: `/branches/cart/device/${id}?uniqueId=${uniqueId}`,
      }),
      transformResponse: (response: ChaletApiResponse<Branch>, meta, arg) =>
        response.data,
      providesTags: ['carts'],
    }),
    createCartItem: builder.mutation({
      query: ({
        productId,
        quantity,
        notes,
        name,
        variations,
        userCartId,
      }) => ({
        method: 'POST',
        url: `/user-cart-items`,
        body: {
          productId,
          quantity,
          notes,
          name,
          variations,
          userCartId,
        },
      }),
      invalidatesTags: ['carts'],
    }),
    incrementCartItem: builder.mutation({
      query: ({ id }) => ({
        method: 'PUT',
        url: `/user-cart-items/increment/${id}`,
      }),
      invalidatesTags: ['carts'],
    }),
    decrementCartItem: builder.mutation({
      query: ({ id }) => ({
        method: 'PUT',
        url: `/user-cart-items/decrement/${id}`,
      }),
      invalidatesTags: ['carts'],
    }),
    deleteCartItem: builder.mutation({
      query: ({ id }) => ({
        method: 'DELETE',
        url: `/user-cart-items/${id}`,
      }),
      invalidatesTags: ['carts'],
    }),

    updateUserCart: builder.mutation({
      query: ({ cartId, userId }) => ({
        method: 'PUT',
        url: `/user-carts/${cartId}`,
        userId,
      }),
      invalidatesTags: ['carts'],
    }),
    getBranchesByProductType: builder.query<
      {
        branches: BranchWithTodayScheduleUI[];
        productCategories: ProductCategory[];
      },
      {
        type: string;
        sectorId: number;
        limit: number;
        offset: number;
        day: number;
      }
    >({
      query: ({ type, sectorId, limit, offset, day }) => ({
        method: 'POST',
        url: '/branches/by/type',
        body: { type, sectorId, day },
        params: { limit, offset },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.type}`;
      },
      merge: (currentCache, newItems, { arg: { offset } }) => {
        if (offset === 0) currentCache.branches.length = 0;

        currentCache.branches.push(...newItems.branches);

        currentCache.productCategories = newItems.productCategories;
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.type !== previousArg?.type ||
        currentArg?.sectorId !== previousArg?.sectorId ||
        currentArg?.limit !== previousArg?.limit ||
        currentArg?.offset !== previousArg?.offset ||
        currentArg?.day !== previousArg?.day,
      transformResponse: (
        response: ChaletApiResponse<{
          branches: Branch[];
          productCategories: ProductCategory[];
        }>,
        meta,
        arg,
      ) => {
        return {
          branches: transformProductTypeWithTodaySchedule(
            response.data.branches,
          ),
          productCategories: response.data.productCategories,
        };
      },
    }),
    validateLocationInSector: builder.query({
      query: ({ branchSectorId, lat, lng }) => ({
        method: 'POST',
        url: `/user-addresses/validate-location`,
        body: { branchSectorId, lat, lng },
      }),
    }),
    createOrderDetail: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: `/order-details`,
        body,
      }),
      invalidatesTags: ['Orders', 'OneOrder'],
    }),
    getSectorSettings: builder.query<settings, number>({
      query: (id) => ({
        method: 'GET',
        url: `/sectors/settings/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<settings>) =>
        response.data,
    }),
    getBranchesByProductCategory: builder.query<
      BranchWithTodayScheduleUI[],
      {
        productCategoryId: number;
        sectorId: number;
        limit: number;
        offset: number;
      }
    >({
      query: ({ productCategoryId, sectorId, limit, offset }) => ({
        method: 'POST',
        url: '/branches/by/category',
        body: { productCategoryId, sectorId },
        params: { limit, offset },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg: { offset } }) => {
        if (offset === 0) currentCache.length = 0;

        currentCache.push(...newItems);
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
      transformResponse: (response: ChaletApiResponse<Branch[]>, meta, arg) => {
        return transformProductCategoryWithTodaySchedule(response);
      },
    }),
    getAllProductTypes: builder.query<ProductTypeResponse[], void>({
      query: () => ({
        method: 'GET',
        url: `/product-types`,
      }),
      transformResponse: (
        response: ChaletApiResponse<ProductTypeResponse[]>,
        meta,
        arg,
      ) => response.data,
    }),
    deleteMyAccount: builder.mutation<{success: boolean, data: any}, {code: string, phone: string}>({
      query: (body) => ({
        method: 'DELETE',
        url: `/users/account`,
        body,
      }),
    })
  }),
});

export default chaletApi;
