import { Box, useTheme } from '@mui/material';
import React, { useEffect, ReactNode } from 'react';

interface PageProps {
  children: ReactNode;
  title?: string;
  bgColor?: string;
  withContainer?: boolean;
  containerSize?: 'sm' | 'md' | 'lg';
}

export const Page: React.FC<PageProps> = ({
  children,
  title = '',
  bgColor,
  withContainer = true,
  containerSize = 'md',
}) => {
  const theme = useTheme();
  useEffect(() => {
    document.title = title;
  }, []);
  let content = children;
  if (withContainer) {
    content = (
      <MaxWidthContainer containerSize={containerSize}>
        {children}
      </MaxWidthContainer>
    );
  }
  return (
    <Box bgcolor={bgColor || theme.palette.background.default}>{content}</Box>
  );
};

const sizes: Record<'sm' | 'md' | 'lg', number> = {
  sm: 500,
  md: 900,
  lg: 1200,
};

interface MaxWidthContainerProps {
  children: ReactNode;
  containerSize: 'sm' | 'md' | 'lg';
}

const MaxWidthContainer: React.FC<MaxWidthContainerProps> = ({
  children,
  containerSize,
}) => {
  return (
    <Box width="100%" maxWidth={sizes[containerSize]} margin="0 auto">
      {children}
    </Box>
  );
};
