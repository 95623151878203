import React from 'react';
import ReduxProvider from './ReduxProvider';
import { Toaster } from 'react-hot-toast';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

type Props = { children: React.ReactNode };

function RootProvider({ children }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ReduxProvider>
        <Toaster />
        {children}
      </ReduxProvider>
    </LocalizationProvider>
  );
}

export default RootProvider;
