import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import chaletApi from 'api/chaletApi';
import toast from 'react-hot-toast';
import shopImage from '../../../assets/img-shop-bike.webp';
import { useAppSelector } from 'redux/hook';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const schema = yup.object().shape({
  name: yup.string().required('El nombre del negocio es obligatorio'),
  address: yup.string().required('La dirección del negocio es obligatoria'),
  type: yup.string().required('El tipo de negocio es obligatorio'),
  userName: yup.string().required('El nombre es obligatorio'),
  userPhone: yup
    .string()
    .phone('SV', 'Ingrese un número de teléfono válido')
    .max(9, 'Máximo 8 dígitos')
    .required('El número de teléfono es obligatorio'),
  userEmail: yup
    .string()
    .email('Ingrese un correo electrónico válido')
    .required('El correo electrónico es obligatorio'),
});
const MaskNumber = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)$/, '$1');
};
type FormData = {
  name: string;
  address: string;
  type: string;
  userName: string;
  userPhone: string;
  userEmail: string;
};
export const ShopRegisterForm = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const [registerShopWithUser, registerResult] =
    chaletApi.useRegisterShopWithUserMutation();
  const [getAllProductTypes, result] =
    chaletApi.useLazyGetAllProductTypesQuery();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isLoggedIn } = useAppSelector((state) => state.userAuthSlice);
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    getAllProductTypes();
  }, [getAllProductTypes]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      address: '',
      type: '',
      userName: '',
      userPhone: '',
      userEmail: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (user) {
      setValue('userName', user.name || '');
      setValue('userPhone', MaskNumber(user.phone) || '');
      setValue('userEmail', user.email || '');
    }
  }, [user, setValue]);

  const onSubmit = (data: FormData) => {
    const { name, address, userEmail, userName, userPhone, type } = data;
    const user = { name: userName, phone: userPhone, email: userEmail };
    const shop = { name, address, type: type as any };

    registerShopWithUser({ user, shop })
      .unwrap()
      .then(
        () => {
          toast.success(
            'Solicitud enviada con éxito. \nEn breve nos pondremos en contacto contigo.',
          );

          reset();
        },
        (error) => {
          if ('data' in error && error.data.error) {
            toast.error(error.data.error);
          }
        },
      );
  };

  useEffect(() => {
    if (user && user.Shops && user.Shops.length > 0) {
      toast.success('Ya tienes un negocio registrado');
      navigate('/perfil');
    }
  }, [user]);

  let cardMessage =
    'Obtén acceso a la plataforma de última generación para administrar tu negocio, incrementar tus ventas y llegar a más clientes.';

  let cartTitle = '¡Regístrate ahora!';

  if (registerResult.isSuccess) {
    cardMessage =
      'Solicitud enviada con éxito. \nEn breve nos pondremos en contacto contigo.';
    cartTitle = 'Solicitud enviada';
  }

  return (
    <Grid
      container
      sx={{ backgroundColor: 'background.default', padding: isMobile ? 2 : 4 }}
      justifyContent="center"
    >
      <Grid
        item
        container
        spacing={isMobile ? 2 : 4}
        sx={{
          maxWidth: 'lg',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} md={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mb: isMobile ? 2 : 4 }}
          >
            <img
              src={shopImage}
              width="100%"
              style={{
                objectFit: 'cover',
                maxWidth: '400px',
                borderRadius: '8px',
              }}
            />
          </Grid>
          <Paper sx={{ backgroundColor: 'primary.main', p: 2, m: 2 }}>
            <Typography variant="h6" color="white.main">
              {cartTitle}
            </Typography>
            <Typography variant="body1" color="white.main">
              {cardMessage}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper
            sx={{
              p: isMobile ? 2 : 4,
              borderRadius: 2,
              maxWidth: isMobile ? '100%' : 500,
              margin: '0 auto',
            }}
            elevation={4}
          >
            <Typography sx={{ mt: 1, mb: 3 }} variant="h4" textAlign="center">
              Registro de Negocio
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Nombre del Negocio"
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                )}
              />
              <Controller
                name="address"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Dirección del Negocio"
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!errors.address}
                    helperText={errors.address?.message}
                  />
                )}
              />
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    name={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    sx={{ mb: 2 }}
                  >
                    {result.data?.map((productType) => (
                      <FormControlLabel
                        key={productType.id}
                        value={productType.key}
                        control={<Radio />}
                        label={productType.name}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
              {errors.type && (
                <Typography variant="body2" color="error">
                  {errors.type.message}
                </Typography>
              )}
              <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                Contacto
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="userName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Nombre"
                        fullWidth
                        error={!!errors.userName}
                        helperText={errors.userName?.message}
                        disabled={isLoggedIn}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="userPhone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Número celular"
                        fullWidth
                        error={!!errors.userPhone}
                        helperText={errors.userPhone?.message}
                        onChange={(event) =>
                          field.onChange(MaskNumber(event.target.value))
                        }
                        disabled={isLoggedIn}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="userEmail"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Correo electrónico"
                        fullWidth
                        error={!!errors.userEmail}
                        helperText={errors.userEmail?.message}
                        disabled={user?.email && isLoggedIn}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 3 }}
                size="large"
              >
                Registrar Negocio
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
