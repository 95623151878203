import dayjs from 'dayjs';

export const getCurrentTime = () => {
  const now = dayjs();
  const formattedTime = now.format('hh:mm A');

  return formattedTime;
};

export function addMinutesToTime(timeString: string, minutesToAdd: number) {
  const time = dayjs(timeString, 'hh:mm A');
  const newTime = time.add(minutesToAdd, 'minute');
  return newTime.format('hh:mm A');
}
