import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrderDetailState {
  orderId: number | null;
}

const initialState: OrderDetailState = {
  orderId: null,
};

export const orderDetailSlice = createSlice({
  name: 'order-detail',
  initialState,
  reducers: {
    setCurrentOrder: (state, action: PayloadAction<number | null>) => {
      state.orderId = action.payload;
    },
  },
});

export const { setCurrentOrder } = orderDetailSlice.actions;

export default orderDetailSlice;
