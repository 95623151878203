import { AccessTime, LocationOn } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import chaletApi from 'api/chaletApi';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hook';
import { DeliveryData } from 'types/DeliveryData';
import { ShopCartItem } from 'types/ShopCart';
import { addMinutesToTime, getCurrentTime } from 'utils/hours';
import { CustomTypography } from '../style';
import { RouteMap } from 'ui/components/partials/RouteMap';
import { Branch } from 'types/Branch';
import { OrderProcesses } from '../OrderProcesses';

// Función para calcular el precio de entrega
const calculateDeliveryPrice = (
  distanceValue: number,
  deliveryDistanceFactor: number,
  orderProcessPrice: number,
) => {
  let deliveryPrice = orderProcessPrice;
  if (distanceValue > deliveryDistanceFactor) {
    const distanceFactor = distanceValue / deliveryDistanceFactor;
    deliveryPrice = parseFloat((orderProcessPrice * distanceFactor).toFixed(2));
  }

  return deliveryPrice;
};

// Función para calcular el tiempo estimado de entrega
const calculateEstimatedDeliveryTime = (
  route: any,
  currentShopDeliveryTime: number,
) => {
  const durationValue = route.routes[0].legs[0].duration.value;
  const RoundedDuration = Math.ceil(durationValue / 60);
  return RoundedDuration + currentShopDeliveryTime + 10;
};

const weightedAverage = (
  distancePrice: number,
  timePrice: number,
  weightDistance = 0.6,
  weightTime = 0.4,
) => {
  return (
    (distancePrice * weightDistance + timePrice * weightTime) /
    (weightDistance + weightTime)
  );
};

type RouteOrderProps = {
  branch: Branch;
  setDeliveryPrice: (deliveryPrice: number) => void;
  setDeliveryData: (data: DeliveryData | null) => void;
  route: any;
  currentOrderProcessId: (id: number) => void;
  currentOrderProcessStepId: (id: number) => void;
  setIsValidLocation: (isValidLocation: boolean) => void;
  isValidLocation: boolean;
};

export const RouteOrder: React.FC<RouteOrderProps> = ({
  branch,
  setDeliveryPrice,
  setDeliveryData,
  route,
  currentOrderProcessId,
  currentOrderProcessStepId,
  setIsValidLocation,
  isValidLocation,
}) => {
  const [currentOrderProcess, setCurrentOrderProcess] = useState<number | null>(
    null,
  );
  const location = useAppSelector((state) => state.locationSlice);

  const { data: orderProcess, isFetching: orderIsFetching } =
    chaletApi.useGetOneOrderProcessQuery(
      currentOrderProcess ? currentOrderProcess : 1,
    );

  const [getSettings, { data: settings, isLoading: isLoadingSettings }] =
    chaletApi.useLazyGetSectorSettingsQuery();

  const [validateLocation, result] =
    chaletApi.useLazyValidateLocationInSectorQuery();

  useEffect(() => {
    if (branch && location && location.sectorId) {
      validateLocation({
        branchSectorId: branch.sectorId,
        lat: location.location?.coordinates[1],
        lng: location.location?.coordinates[0],
      })
        .unwrap()
        .then((data) => {
          if (data.success) {
            setIsValidLocation(true);
          } else {
            setIsValidLocation(false);
          }
        });

      getSettings(location.sectorId);
    }
  }, [branch, location, validateLocation, getSettings]);

  const [estimatedDeliveryTime, setEstimatedDeliveryTime] =
    React.useState<number>();

  const [currentTime, setCurrentTime] = React.useState<string>(
    getCurrentTime(),
  );

  const handleCurrentOrderProcess = (id: number) => {
    setCurrentOrderProcess(id);
  };

  const routeDeliveryTime = route.routes[0].legs[0].duration.value;
  const routeDistance = route.routes[0].legs[0].distance.value;

  const totalDeliveryPrice = useMemo(() => {
    if (!routeDistance || !orderProcess || !routeDeliveryTime || !settings) {
      return null;
    }
    console.log(settings);
    const distanceFactor = settings.distanceFactor;
    const f = routeDistance / distanceFactor;
    const distanceDeliveryPrice = orderProcess.price * f;

    const timeFactor = settings.timeFactor;
    const f2 = routeDeliveryTime / timeFactor;
    const timeDeliveryPrice = orderProcess.price * f2;

    let deliveryPrice = weightedAverage(
      distanceDeliveryPrice,
      timeDeliveryPrice,
    );

    if (deliveryPrice < orderProcess.price) {
      deliveryPrice = orderProcess.price;
    }

    return deliveryPrice;
  }, [routeDistance, routeDeliveryTime, orderProcess, settings]);

  const deliveryTime = useMemo(() => {
    const estimatedDeliveryTime = calculateEstimatedDeliveryTime(
      route,
      branch.estimatedOrderTime,
    );

    return estimatedDeliveryTime;
  }, [route, branch]);

  React.useEffect(() => {
    if (
      result.data &&
      result.data.success &&
      orderProcess &&
      route &&
      typeof totalDeliveryPrice === 'number'
    ) {
      const newDeliveryData = {
        deliveryPrice: totalDeliveryPrice,
        estimatedDeliveryTime: `${deliveryTime} minutes`,
        deliveryType: orderProcess.name,
      };
      setDeliveryData(newDeliveryData);
      setDeliveryPrice(totalDeliveryPrice);
    } else if (result.data && !result.data.success) {
      setDeliveryData(null);
      setDeliveryPrice(0);
    }

    setEstimatedDeliveryTime(deliveryTime);
  }, [result, route, orderProcess, totalDeliveryPrice, deliveryTime]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 60000); // 60000 milisegundos = 1 minuto

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, []);

  if (orderIsFetching || isLoadingSettings) {
    return (
      <Box
        sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
        padding={4}
      >
        <Box>
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    );
  }

  return (
    <>
      {isValidLocation ? (
        <>
          <Grid item md={12} sm={12} xs={12} mt={1}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Box flex={0} mr={1}>
                <LocationOn />
              </Box>
              <Box flex={1}>
                <CustomTypography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {location.address}
                </CustomTypography>
              </Box>
            </Box>
            <Divider variant="middle"></Divider>
          </Grid>
          <Grid item md={12} sm={12} xs={12} mt={1}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Box flex={0} mr={1}>
                <AccessTime />
              </Box>
              <Box flex={1}>
                <CustomTypography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Hora estimada de entrega:{' '}
                </CustomTypography>
              </Box>
              <Box flex={0}>
                <Typography variant="body2" noWrap>
                  {estimatedDeliveryTime
                    ? ` ${addMinutesToTime(currentTime, estimatedDeliveryTime)}`
                    : ''}
                </Typography>
              </Box>
            </Box>
            <Divider variant="middle"></Divider>
          </Grid>
          <Grid item md={12} sm={12} xs={12} mt={1}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <OrderProcesses
                currentOrderProcessId={currentOrderProcessId}
                currentOrderProcessStepId={currentOrderProcessStepId}
                changeCurrentOrderProcess={handleCurrentOrderProcess}
              />
            </Box>
          </Grid>
        </>
      ) : (
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 4,
          }}
          elevation={0}
        >
          <CardMedia>
            <InfoIcon color="primary" sx={{ fontSize: 60 }} />
          </CardMedia>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              No podemos completar su orden.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              La ubicación seleccionada para entregar el pedido esta fuera del
              área de cobertura de la sucursal, por favor cambie o actualize su
              ubicación e intentelo nuevamente
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};
